
.top-banner {
  height:200px;
  background:transparent;
  position: relative;
  margin-left:50px;
  margin-right:50px;
  margin-top:10px;
  border: 1px solid rgba(0,0,0,.1);
  border-radius: 3px;
  box-shadow: 0 5px 10px rgba(0,0,0,.1);
  z-index:10;
}
@media (max-width: 1199.98px) {
.top-banner {
    margin-left:15px;
    margin-right:15px;
}
}
@media (max-width: 575.98px) {
.top-banner {
    margin:0px;
}
}
.top-banner .Masthead {
  border-radius: 3px;
}



.language-selector ul.dropdown-menu {
    background-color: #fff !important;
    box-shadow: 0px 13px 42px 11px rgba(0,0,0,.05);
    border: 1px solid #eee;
    border-top: 2px solid var(--secondary-color);
    margin: 0;
    padding: 0;
    width:125px;
    min-width: 125px;
    transition: none;
    border-radius: 0;
}
.language-selector ul.dropdown-menu a:hover {
    padding-left:18px;
}
.language-selector ul.dropdown-menu a {
    font-size: 12px;
    font-weight: 600;
    color: #666;
    padding: 10px 15px;
    border: 0;
    letter-spacing: 0;
    font-family: 'Lato',sans-serif;
    -webkit-transition: all .2s ease-in-out;
    -o-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;

    line-height: 22px;
    text-transform: uppercase;
}
.language-selector ul.dropdown-menu a img {
    margin-right: 10px;
}



#top-cart i.icon-shopping-cart {
    font-size: 20px;
}
#top-cart.header-misc-icon > a {
    height:22px;
    line-height: 22px;
    transition: color 0s ease-in-out;
}
#top-cart .top-cart-number {
    top: -11px;
    left: 13px;
    font-size: 14px;
    color: #FFF;
    text-shadow: 1px 1px 1px rgba(0,0,0,0.2);
    width: 20px;
    height: 20px;
    line-height: 20px;
    text-align: center;

    font-weight: 700;
    font-family: 'Poppins', sans-serif;
}

/*
.top-cart-items {
    display: flex;
    flex-direction: column;
    align-items: stretch;
}
*/

/*
.top-cart-content .top-cart-action .top-checkout-price {
    margin-right:20px;
}

/* We apply specific style for top cart content when opened as tooltip */
/*
@media (min-width: 992px) {
    .top-cart-content {
        opacity: 0;
        z-index: -2;
        position: absolute;
        width: auto;
        background-color: var(--content-background-color);
        box-shadow: 0px 20px 50px 10px rgba(0, 0, 0, 0.05);
        border: 1px solid #EEE;
        border-top: 2px solid var(--secondary-color);
        right: -15px;
        left: auto;
        top: calc(100% + 2px);
        margin: -10000px 0 0;
        -webkit-transition: opacity .5s ease, top .4s ease;
        -o-transition: opacity .5s ease, top .4s ease;
        transition: opacity .5s ease, top .4s ease;
    }
}

/* We apply specific style for top cart content when opened as fixed from the right */
/*
@media (max-width: 991.8px) {
    .top-cart-open .top-cart-content {
        width: auto;
    }
}

.top-cart-content .top-cart-title .btn-close {
    display: none;
}

@media (max-width: 991px) {
    .top-cart-content .top-cart-title .btn-close {
        display: block;
        position:absolute;
        top:10px;
        right:10px;
    }
}
*/



.language-selector ul.dropdown-menu {
    background-color: #fff !important;
    box-shadow: 0px 13px 42px 11px rgba(0,0,0,.05);
    border: 1px solid #eee;
    border-top: 2px solid var(--secondary-color);
    margin: 0;
    padding: 0;
    width:125px;
    min-width: 125px;
    transition: none;
    border-radius: 0;
}
.language-selector ul.dropdown-menu a:hover {
    padding-left:18px;
}
.language-selector ul.dropdown-menu a {
    font-size: 12px;
    font-weight: 600;
    color: #666;
    padding: 10px 15px;
    border: 0;
    letter-spacing: 0;
    font-family: 'Lato',sans-serif;
    -webkit-transition: all .2s ease-in-out;
    -o-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;

    line-height: 22px;
    text-transform: uppercase;
}
.language-selector ul.dropdown-menu a img {
    margin-right: 10px;
}



body.primary-menu-open .menu-container {
    display: block;
}



@media (min-width: 992px) {
  /* We ensure that header wrap clone has same size as initial header */
#header.square-logo .header-wrap-clone {
    height:60px;
}

  /* We ensure that content to be included within header (ex: slider) has the right margin */
#header.square-logo + .include-header {
		margin-top: calc( -60px - 2px );
}
#header.transparent-header.dark.square-logo #logo {
    background-color:transparent;
    border-color:transparent;
    box-shadow:none;
}
}

/* We ensure that links, language selector and top cart are pushed to the right */
#header .header-row {
  justify-content: flex-end;
}

/* We position the logoc in absolute position on top left */
#header.full-header.square-logo #logo {
  position: absolute;
  left: 71px;
  top: 20px;
  height: 200px;
  width: 200px;
  max-height: none;
  padding: 5px;
  background-color: #fff;
  border: 1px solid rgba(0,0,0,0.075);
  border-radius: 3px;
  box-shadow: 0 0 10px rgb(0 0 0 / 10%);
  z-index: 15;

  transition: height .4s ease, width .4s ease, top .4s ease,margin .4s ease,opacity .2s .2s ease;
}
@media (max-width: 991.98px) {

  /* We keep the logo to the left and reduce size */
#header.full-header.square-logo #logo {
    left:50px;
    height: 150px;
    width: 150px;
}

  /* We reduce size of the logo when menu is opened */
body.primary-menu-open #header.full-header.square-logo #logo {
    top:0px;
    height: 100px;
    width: 100px;
}

  /* We move the menu down to be just below the logo (60px header banner + 40px margin = 100px logo height) */
body.primary-menu-open #header.full-header .primary-menu {
    margin-top: 40px;
}
}
@media (max-width: 575.98px) {
  /* We move back the logo to the left */
#header.full-header.square-logo #logo {
    left:0px;
    transform: none;
}
}

/* We set specific size for the shop logo */
#header.full-header.sticky-header.square-logo #logo,
#header.full-header.square-logo.square-mini #logo {
  height:100px;
  width:100px;
}

/* We set specific size for the shop logo */
#header.full-header.square-logo #logo img {
  height:auto!important;
  padding:0!important;
}

/* We ensure that the header is reduced */
#header.square-logo .dropdown .menu-link,
#header.square-logo #top-cart.header-misc-icon {
  padding-top: 17px;
  padding-bottom: 17px;
}

/* We ensure that the header is reduced */
#header.square-logo .menu-container > .menu-item > .menu-link {
  padding-top: 19px;
  padding-bottom: 19px;
}
  

